/* For smallest screens */

@media screen and (max-width: 768px) {

  body {
    background-color: #F2F2F2;
    font-family: UPS Berlingske Sans, sans-serif;
  }

  .header {
    background-color: #009CBD;
    min-height: 100px;
    height: 140px;
    display: flex;
    align-items: center;
    position: relative;
  }

  .search-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    transform: translateY(-50%);
  }

  .search {
    flex-grow: 1;
    box-sizing: border-box;
    border-width: 5px;
    border-color: black;
    border-style: solid;
    border-radius: 28px;
    width: 100%;
    font-size: 100%;
    font-weight: 800;
    color: #BDB7B3;
    padding: 0 4vw;
    /* margin-right: -10vw; */
    /* margin-left: -3vw; */
    min-height: 60px;
    height: 5vh;
  }

  .search-icon {
    position: absolute;
    /* padding-left: 55%; */
    /* margin-right: 10%; */
    /* translate: -12vw; */
    right: 12px;
    color: #BDB7B3;
    z-index: 1;
    transform: -30vw;
    height: auto;
  }

  .main {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: initial; */
    height: 100vh; /* Adjust the height as needed */
    margin: 0 auto; 
    width: 85%; 
    /* padding-top: 10vh; */
  }


  .center-list {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .center-list-header {
    display: flex;
    justify-content: space-between;
    /* align-items: flex-end; */
    align-items: center;
    /* height: 10vh; */
    width: 100%;
    /* position: relative; */
    padding-bottom: 2vh;
    margin-top: 50px;
  }

  .center-list-header-right {
    display: flex;
    justify-content: space-around;
  }

  .all-centers-header {
    font-size: 100%;
    font-weight: 800;
    /* vertical-align: bottom; */
    margin-bottom: 17.5px;
    /* margin: 0px; */
    /* width: 100%; */
    /* align-self: flex-start; */
  }

  .sort-by-header {
    font-size: 100%;
    font-weight: 800;
    margin-right: 140px;
    /* vertical-align: bottom; */
    /* align-self: center; */
    width: 100%;
    /* z-index: 1; */
  }

  .sort-by-dropdown {
    font-size: 100%;
    font-weight: 800;
    /* margin-right: 200px; */
    /* vertical-align: bottom; */
    /* padding: 0 10px; */
    width: 160px;
    color: #009CBD;
    position: absolute;
    z-index: 1;
  }

  .Dropdown-root.sort-by-dropdown.is-open {
    /* make dropdown menu not move when seleceted */
    position: absolute;
    z-index: 1;
    margin: 0;
    /* width: 100%; */
    width: 130px;
  }

  .Dropdown-menu {
    border: 3px solid black;
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    /* position: relative; */
    /* width: 200px; */
  }

  .sort-by-dropdown-container {
    display: flex;
    /* flex-direction: column; */
    justify-content: flex-end;
    /* align-items: flex-end; */
    /* position: relative; */
  }

  .center-list-card {
    display: flex;
    padding: 10px 12px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 12px;
    background: var(--white, #FFF);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  }

  .center-list-cards {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    /* align-items: flex-start;  */
    gap: 8px;
  }

  .center-name{
    font-size: 28px;
    font-weight: 800;
    /* margin: 0px; */
  }

  .center-address {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .center-wait{
    color: #404040;
    /* text-align: center; */
    /* Body regular */
    /* font-family: UPS Berlingske Sans; */
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 120% */
    opacity: 0.55;
    padding-right: 10px;
  }

  .center-wait-time{
    font-size: 23px;
    font-weight: 800;
    color:#009CBD;
    padding-right: 15px;
    /* margin: 0px; */
  }

  .center-list-icon {
    width: 24px;
    height: 24px;
    /* transform: rotate(-90deg);
    flex-shrink: 0; */
  }

  .login-status-header {
    display: flex;
    justify-content: space-between;
    justify-content: flex-end;
    align-self: center;
    align-items: center;
    height: 10vh;
    width: 100%;
    /* padding: 0 40px; */
    /* position: relative; */
    /* padding-bottom: 2vh; */
  }

  .login-status {
    display: flex;
    /* justify-content: flex-end; */
    margin-right: 50px;
    align-items: center;
    margin-bottom: 50px;
  }

  .login-text {
    color: var(--white, #FFF);
    text-align: center;
    /* font-family: UPS Berlingske Sans; */
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 123.81% */
    padding-right: 4px;
    opacity: 0.55;
  }

  .user-text {
    color: var(--white, #FFF);
    text-align: center;
    /* font-family: UPS Berlingske Sans; */
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 123.81% */
    padding-right: 12px;
  }

  .logout-link {
    color: var(--white, #FFF);
    text-align: center;
    /* font-family: UPS Berlingske Sans; */
    font-size: 23px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px; /* 121.739% */
    text-decoration-line: underline;
    /* padding: 0 10px; */
  }

  .App {
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  /* .myArrowClassName {
    color: #009CBD;
    font-size: 30px;
    font-weight: 800;
    padding: 0 10px;
  } */

  .arrow-closed {
    color: #009CBD;
    font-size: 30px;
    font-weight: 800;
    /* padding: 0 10px; */
  }

  .arrow-open {
    color: #009CBD;
    font-size: 30px;
    font-weight: 800;
    /* padding: 0 10px; */
    transform: rotate(180deg);
  }

  .login-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    padding: 5vw;
    margin-top: 60px;
  }

  .login-header {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width: 100%;
    /* justify-content: center; */
    /* margin-bottom: 50px; */
  }

  .login-greeting {
    color: #000;
    /* font-family: UPS Berlingske Sans; */
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 28px; */
    margin: 0px;
  }

  .login-subheader {
    color: #000;
    /* font-family: UPS Berlingske Sans; */ 
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    /* line-height: 56px; 116.667% */
    margin-top: 14px;
  }

  .login-form-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    /* align-self: center; */
    /* width: 60%; */
    /* margin-top: 60px; */
  }

  .login-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    /* justify-content: center; */
    /* align-self: center; */
    width: 100%;
    /* margin-top: 60px; */
  }

  .login-label {
    color: #000;
    /* font-family: UPS Berlingske Sans; */
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .login-input {
    display: flex;
    height: 48px;
    /* padding: 10px 40px; */
    align-items: center;
    /* gap: 10px; */
    align-self: stretch;
    border-radius: 22px;
    border: 6px solid #9CA3AF;
    background: #DDD;
    /* width: 100%; */
    /* max-width: fit-content; */
  }

  .login-button-container {
    display: flex;
    margin-top: 25vh;
    flex-direction: column;
    align-items: center;
    /* align-self: stretch; */
    /* set container at bottom of div class .login */
  }

  .login-button {
    display: flex;
    width: 264px;
    height: 64px;
    padding: 10px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    border: 6px solid #000;
    background: #FFD300;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.50);
    color: #000;
    text-align: center;
    /* Header h2 */
    /* font-family: UPS Berlingske Sans; */
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; /* 123.077% */
  }

  .login-button:hover {
    background: #DDD;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.50);
    color: #000;
  }

  .login-button:active {
    background: #DDD;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.50);
    color: #000;
  }

  .login-button:focus {
    background: #DDD;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.50);
    color: #000;
  }

  .forgot-password {
    color: var(--blue, #009CBD);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
  }



  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

}


/* For middle screen size */

@media screen and (min-width: 769px) and (max-width: 1024px) {

  body {
    background-color: #F2F2F2;
    font-family: UPS Berlingske Sans, sans-serif;
  }

  .header {
    background-color: #009CBD;
    min-height: 100px;
    height: 140px;
    display: flex;
    align-items: center;
    position: relative;
  }

  .search-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    transform: translateY(-50%);
  }

  .search {
    border-width: 5px;
    border-color: black;
    border-style: solid;
    border-radius: 28px;
    width: 100%;
    font-size: 30px;
    font-weight: 800;
    color: #BDB7B3;
    padding: 0 40px;
    margin-right: -40px;
    /* margin-left: 40px; */
    min-height: 60px;
    height: 5vh;
  }

  .search-icon {
    position: relative;
    /* padding-left: 55%; */
    /* margin-right: 10%; */
    color: #BDB7B3;
    z-index: 1;
    transform: translateX(-70%);
  }

  .main {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: initial; */
    height: 100vh; /* Adjust the height as needed */
    margin: 0 auto; 
    width: 60%; 
    /* padding-top: 10vh; */
  }


  .center-list {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .center-list-header {
    display: flex;
    justify-content: space-between;
    /* align-items: flex-end; */
    align-items: center;
    /* height: 10vh; */
    width: 100%;
    /* position: relative; */
    padding-bottom: 2vh;
    margin-top: 50px;
  }

  .center-list-header-right {
    display: flex;
    justify-content: space-around;
    align-self: flex-end;
    font-size: 21px;
  }

  .all-centers-header {
    font-size: 28px;
    font-weight: 800;
    vertical-align: bottom;
    margin: 0px;
    /* align-self: flex-start; */
  }

  .sort-by-header {
    font-size: 21px;
    font-weight: 800;
    margin-right: 160px;
    vertical-align: bottom;
    /* z-index: 1; */
  }

  .sort-by-dropdown {
    font-size: 21px;
    font-weight: 800;
    /* margin-right: 200px; */
    vertical-align: bottom;
    /* padding: 0 10px; */
    width: 160px;
    color: #009CBD;
    position: absolute;
    z-index: 1;
  }

  .Dropdown-root.sort-by-dropdown.is-open {
    /* make dropdown menu not move when seleceted */
    position: absolute;
    z-index: 1;
    margin: 0;
    /* width: 100%; */
    width: 160px;
  }

  .Dropdown-menu {
    border: 3px solid black;
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    /* position: relative; */
    width: 200px;
  }

  .sort-by-dropdown-container {
    display: flex;
    /* flex-direction: column; */
    justify-content: flex-end;
    /* align-items: flex-end; */
    /* position: relative; */
  }

  .center-list-card {
    display: flex;
    padding: 10px 10px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 12px;
    background: var(--white, #FFF);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  }

  .center-list-cards {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    /* align-items: flex-start;  */
    gap: 8px;
  }

  .center-name{
    font-size: 28px;
    font-weight: 800;
    /* margin: 0px; */
  }

  .center-address {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .center-wait{
    color: #404040;
    /* text-align: center; */
    /* Body regular */
    /* font-family: UPS Berlingske Sans; */
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 120% */
    opacity: 0.55;
    padding-right: 10px;
  }

  .center-wait-time{
    font-size: 23px;
    font-weight: 800;
    color:#009CBD;
    padding-right: 15px;
    /* margin: 0px; */
  }

  .center-list-icon {
    width: 24px;
    height: 24px;
    /* transform: rotate(-90deg);
    flex-shrink: 0; */
  }

  .login-status-header {
    display: flex;
    justify-content: space-between;
    justify-content: flex-end;
    align-self: center;
    align-items: center;
    height: 10vh;
    width: 100%;
    /* padding: 0 40px; */
    /* position: relative; */
    /* padding-bottom: 2vh; */
  }

  .login-status {
    display: flex;
    /* justify-content: flex-end; */
    margin-right: 50px;
    align-items: center;
    margin-bottom: 50px;
  }

  .login-text {
    color: var(--white, #FFF);
    text-align: center;
    /* font-family: UPS Berlingske Sans; */
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 123.81% */
    padding-right: 4px;
    opacity: 0.55;
  }

  .user-text {
    color: var(--white, #FFF);
    text-align: center;
    /* font-family: UPS Berlingske Sans; */
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 123.81% */
    padding-right: 12px;
  }

  .logout-link {
    color: var(--white, #FFF);
    text-align: center;
    /* font-family: UPS Berlingske Sans; */
    font-size: 23px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px; /* 121.739% */
    text-decoration-line: underline;
    /* padding: 0 10px; */
  }

  .App {
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  /* .myArrowClassName {
    color: #009CBD;
    font-size: 30px;
    font-weight: 800;
    padding: 0 10px;
  } */

  .arrow-closed {
    color: #009CBD;
    font-size: 30px;
    font-weight: 800;
    /* padding: 0 10px; */
  }

  .arrow-open {
    color: #009CBD;
    font-size: 30px;
    font-weight: 800;
    /* padding: 0 10px; */
    transform: rotate(180deg);
  }

  .login-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    /* width: 60%; */
    margin-top: 60px;
  }

  .login-header {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width: 100%;
    /* justify-content: center; */
    /* margin-bottom: 50px; */
  }

  .login-greeting {
    color: #000;
    /* font-family: UPS Berlingske Sans; */
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 28px; */
    margin: 0px;
  }

  .login-subheader {
    color: #000;
    /* font-family: UPS Berlingske Sans; */ 
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    /* line-height: 56px; 116.667% */
    margin-top: 14px;
  }

  .login-form-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    /* align-self: center; */
    /* width: 60%; */
    /* margin-top: 60px; */
  }

  .login-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    /* justify-content: center; */
    /* align-self: center; */
    width: 100%;
    /* margin-top: 60px; */
  }

  .login-label {
    color: #000;
    /* font-family: UPS Berlingske Sans; */
    font-size: 39px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .login-input {
    display: flex;
    height: 48px;
    padding: 10px 40px;
    align-items: center;
    /* gap: 10px; */
    /* align-self: stretch; */
    border-radius: 28px;
    border: 6px solid #9CA3AF;
    background: #DDD;
    width: 88.77%;
  }

  .login-button-container {
    display: flex;
    margin-top: 56px;
    flex-direction: column;
    align-items: center;
  }

  .login-button {
    display: flex;
    width: 264px;
    height: 72px;
    padding: 10px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    border: 6px solid #000;
    background: #FFD300;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.50);
    color: #000;
    text-align: center;
    /* Header h2 */
    /* font-family: UPS Berlingske Sans; */
    font-size: 39px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; /* 123.077% */
  }

  .login-button:hover {
    background: #DDD;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.50);
    color: #000;
  }

  .login-button:active {
    background: #DDD;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.50);
    color: #000;
  }

  .login-button:focus {
    background: #DDD;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.50);
    color: #000;
  }

  .forgot-password {
    color: var(--blue, #009CBD);
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

}

/* For largest screens */

@media screen and (min-width: 1025px) {

  body {
    background-color: #F2F2F2;
    font-family: UPS Berlingske Sans, sans-serif;
  }

  .header {
    background-color: #009CBD;
    min-height: 100px;
    height: 140px;
    display: flex;
    align-items: center;
    position: relative;
  }

  .search-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    transform: translateY(-50%);
  }

  .search {
    border-width: 5px;
    border-color: black;
    border-style: solid;
    border-radius: 28px;
    width: 100%;
    font-size: 30px;
    font-weight: 800;
    color: #BDB7B3;
    padding: 0 40px;
    margin-right: -40px;
    /* margin-left: 40px; */
    min-height: 60px;
    height: 5vh;
  }

  .search-icon {
    position: relative;
    /* padding-left: 55%; */
    /* margin-right: 10%; */
    color: #BDB7B3;
    z-index: 1;
    transform: translateX(-70%);
  }

  .main {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: initial; */
    height: 100vh; /* Adjust the height as needed */
    margin: 0 auto; 
    width: 60%; 
    /* padding-top: 10vh; */
  }


  .center-list {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .center-list-header {
    display: flex;
    justify-content: space-between;
    /* align-items: flex-end; */
    align-items: center;
    /* height: 10vh; */
    width: 100%;
    /* position: relative; */
    padding-bottom: 2vh;
    margin-top: 50px;
  }

  .center-list-header-right {
    display: flex;
    justify-content: space-around;
    align-self: flex-end;
    font-size: 21px;
  }

  .all-centers-header {
    font-size: 28px;
    font-weight: 800;
    vertical-align: bottom;
    margin: 0px;
    /* align-self: flex-start; */
  }

  .sort-by-header {
    font-size: 21px;
    font-weight: 800;
    margin-right: 160px;
    vertical-align: bottom;
    /* z-index: 1; */
  }

  .sort-by-dropdown {
    font-size: 21px;
    font-weight: 800;
    /* margin-right: 200px; */
    vertical-align: bottom;
    /* padding: 0 10px; */
    width: 160px;
    color: #009CBD;
    position: absolute;
    z-index: 1;
  }

  .Dropdown-root.sort-by-dropdown.is-open {
    /* make dropdown menu not move when seleceted */
    position: absolute;
    z-index: 1;
    margin: 0;
    /* width: 100%; */
    width: 160px;
  }

  .Dropdown-menu {
    border: 3px solid black;
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    /* position: relative; */
    width: 200px;
  }

  .sort-by-dropdown-container {
    display: flex;
    /* flex-direction: column; */
    justify-content: flex-end;
    /* align-items: flex-end; */
    /* position: relative; */
  }

  .center-list-card {
    display: flex;
    padding: 10px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 12px;
    background: var(--white, #FFF);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  }

  .center-list-cards {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    /* align-items: flex-start;  */
    gap: 8px;
  }

  .center-name{
    font-size: 28px;
    font-weight: 800;
    /* margin: 0px; */
  }

  .center-address {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .center-wait{
    color: #404040;
    /* text-align: center; */
    /* Body regular */
    /* font-family: UPS Berlingske Sans; */
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 120% */
    opacity: 0.55;
    padding-right: 10px;
  }

  .center-wait-time{
    font-size: 23px;
    font-weight: 800;
    color:#009CBD;
    padding-right: 15px;
    /* margin: 0px; */
  }

  .center-list-icon {
    width: 24px;
    height: 24px;
    /* transform: rotate(-90deg);
    flex-shrink: 0; */
  }

  .login-status-header {
    display: flex;
    justify-content: space-between;
    justify-content: flex-end;
    align-self: center;
    align-items: center;
    height: 10vh;
    width: 100%;
    /* padding: 0 40px; */
    /* position: relative; */
    /* padding-bottom: 2vh; */
  }

  .login-status {
    display: flex;
    /* justify-content: flex-end; */
    margin-right: 50px;
    align-items: center;
    margin-bottom: 50px;
  }

  .login-text {
    color: var(--white, #FFF);
    text-align: center;
    /* font-family: UPS Berlingske Sans; */
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 123.81% */
    padding-right: 4px;
    opacity: 0.55;
  }

  .user-text {
    color: var(--white, #FFF);
    text-align: center;
    /* font-family: UPS Berlingske Sans; */
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 123.81% */
    padding-right: 12px;
  }

  .logout-link {
    color: var(--white, #FFF);
    text-align: center;
    /* font-family: UPS Berlingske Sans; */
    font-size: 23px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px; /* 121.739% */
    text-decoration-line: underline;
    /* padding: 0 10px; */
  }

  .App {
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  /* .myArrowClassName {
    color: #009CBD;
    font-size: 30px;
    font-weight: 800;
    padding: 0 10px;
  } */

  .arrow-closed {
    color: #009CBD;
    font-size: 30px;
    font-weight: 800;
    /* padding: 0 10px; */
  }

  .arrow-open {
    color: #009CBD;
    font-size: 30px;
    font-weight: 800;
    /* padding: 0 10px; */
    transform: rotate(180deg);
  }

  .login-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    /* width: 60%; */
    margin-top: 60px;
  }

  .login-header {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width: 100%;
    /* justify-content: center; */
    /* margin-bottom: 50px; */
  }

  .login-greeting {
    color: #000;
    /* font-family: UPS Berlingske Sans; */
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 28px; */
    margin: 0px;
  }

  .login-subheader {
    color: #000;
    /* font-family: UPS Berlingske Sans; */ 
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    /* line-height: 56px; 116.667% */
    margin-top: 14px;
  }

  .login-form-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    /* align-self: center; */
    /* width: 60%; */
    /* margin-top: 60px; */
  }

  .login-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    /* justify-content: center; */
    /* align-self: center; */
    width: 100%;
    /* margin-top: 60px; */
  }

  .login-label {
    color: #000;
    /* font-family: UPS Berlingske Sans; */
    font-size: 39px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .login-input {
    display: flex;
    height: 48px;
    padding: 10px 40px;
    align-items: center;
    /* gap: 10px; */
    /* align-self: stretch; */
    border-radius: 28px;
    border: 6px solid #9CA3AF;
    background: #DDD;
    width: 88.77%;
  }

  .login-button-container {
    display: flex;
    margin-top: 56px;
    flex-direction: column;
    align-items: center;
  }

  .login-button {
    display: flex;
    width: 264px;
    height: 72px;
    padding: 10px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    border: 6px solid #000;
    background: #FFD300;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.50);
    color: #000;
    text-align: center;
    /* Header h2 */
    /* font-family: UPS Berlingske Sans; */
    font-size: 39px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; /* 123.077% */
  }

  .login-button:hover {
    background: #DDD;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.50);
    color: #000;
  }

  .login-button:active {
    background: #DDD;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.50);
    color: #000;
  }

  .login-button:focus {
    background: #DDD;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.50);
    color: #000;
  }

  .forgot-password {
    color: var(--blue, #009CBD);
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

}